body {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    font-family: $font-main;
    letter-spacing: 2px;
    color: var(--black-100);
    background-color: var(--white);
}

.container {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding: unset;
}

.section {
    padding-left: 20px;
    padding-right: 20px;

    &--filled {
        background-color: var(--white);
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: 500;
    }

    h1 {
        font-size: 22px;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 14px;
        color: var(--gray-700);
    }

    a {
        text-decoration: none;
    }

    @include rmin($desktop) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @include rmin($desktop-lg) {
        padding-left: 72px;
        padding-right: 72px;
    }
}