.btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 60px;

    letter-spacing: 2px;
    text-transform: uppercase;
    border: none;

    &--primary {
        background-color: var(--primary-500);
        color: var(--white);
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
    }

    &--black {
        background-color: var(--black);
        color: var(--white);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }
}