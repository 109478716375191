.icon-arrow {
  width: 1.77em;
  height: 1em;
  fill: initial;
}
.icon-back-arrow {
  width: 1.06em;
  height: 1em;
  fill: initial;
}
.icon-checkmark {
  width: 1.33em;
  height: 1em;
  fill: initial;
}
.icon-close-white {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-cover {
  width: 1.08em;
  height: 1em;
  fill: initial;
}
.icon-edit {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-favorite-bg {
  width: 1.14em;
  height: 1em;
  fill: initial;
}
.icon-favorite {
  width: 1.11em;
  height: 1em;
  fill: initial;
}
.icon-filter {
  width: 1.04em;
  height: 1em;
  fill: initial;
}
.icon-gallery {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-hearth {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-home {
  width: 0.77em;
  height: 1em;
  fill: initial;
}
.icon-ico-search {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-instagram {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-logo-black {
  width: 9.25em;
  height: 1em;
  fill: initial;
}
.icon-logo {
  width: 10em;
  height: 1em;
  fill: initial;
}
.icon-payment {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-shoppingbag {
  width: 1.02em;
  height: 1em;
  fill: initial;
}
.icon-telegram {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-truck {
  width: 1.36em;
  height: 1em;
  fill: initial;
}
.icon-vk {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-x {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-youtube {
  width: 1em;
  height: 1em;
  fill: initial;
}
