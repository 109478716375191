.filter {
    position: fixed;
    display: none;

    top: 0;
    left: 0;

    padding: 23px 20px;

    width: 100%;
    height: 100%;
    background-color: var(--white);
    z-index: 12;

    &.active {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        padding-bottom: 100px;
    }

    &-header__back {
        display: flex;
        align-items: center;
        gap: 20px;

        a {
            display: flex;
        }
    }

    &-container {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        div h3 {
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
    }

    &-container__colors-list {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }

    &-container__colors-item {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;
        border-radius: 50%;

        &.active::after {
            position: absolute;

            content: '';

            display: inline-block;
            height: 20px;
            width: 9px;
            
            top: 7px;
            left: 48%;

            border-bottom: 2px solid var(--black);
            border-right: 2px solid var(--black);
            transform: rotate(45deg) translateX(-48%);
        }

        &.white {
            border: 1px solid var(--black);
            background-color: var(--white);
        }
        &.black {
            background-color: var(--black);

            &.active::after {
                border-bottom: 2px solid var(--white);
                border-right: 2px solid var(--white);
            }
        }
        &.grey {
            background-color: #D9D9D9;
        }
        &.orange {
            background-color: #FDB600;
        }
        &.purple {
            background-color: #984995;
        }
        &.green {
            background-color: #63B22F;
        }
        &.blue {
            background-color: #2252B0;
        }
        &.red {
            background-color: #A82420;
        }
        &.pink {
            background-color: #EFA4A2;
        }
        &.brown {
            background-color: #975C17;
        }
        &.yellow {
            background-color: #FFE70D;
        }
        &.blue-light {
            background-color: #A3C2FF;
        }
    }

    &-container__sizes-list,
    &-container__gender-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    &-container__sizes-item,
    &-container__gender-item {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 10px;

        min-width: 50px;
        border: 1px solid var(--black);

        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        text-transform: uppercase;

        &.active {
            background-color: var(--black);
            color: var(--white);
        }
    }

    &-container__price-range {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 20px;
        gap: 14px;
        width: fit-content;
    }

    &-container__price-range input {
        position: relative;
        text-align: center;
        border: none;
        border-bottom: 1px solid #ccc;
        font-size: 16px;
    }

    &-container__price-range input::placeholder {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.5);
    }

    &-container__price-range input:focus {
        outline: none;
        border-bottom: 1px solid #000;
    }


    &-container__range-label {
        color: #aaa;
        font-size: 16px;
    }

    // &-container__checkbox {
    //     margin-bottom: 20px;
    // }

    &-container__checkbox label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        cursor: pointer;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-container__checkbox label span {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
    }

    &-container__checkbox input[type="checkbox"] {
        appearance: none;
        display: flex;
        align-content: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border: 1px solid var(--black);
    }

    &-container__checkbox input[type="checkbox"]::before {
        content: "";
        width: 24px;
        height: 22px;
        transform: scale(0);
        background: url('../../icons/checkmark.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: center;
        background-color: var(--black);
        border: none;
    }

    &-container__checkbox input[type="checkbox"]:checked::before {
        transform: scale(1);
        border: none;
    }
}