.hero {
    &-carousel,
    &-carousel__vertical {
        position: relative;
        width: 100%;

        li {
            position: relative;
        }

        li .shadow {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 57%;
            background: linear-gradient(0.00deg, rgb(0, 0, 0) 1.327%,rgba(0, 0, 0, 0) 44.243%);
            z-index: 1;
        }

        .slick-list {
            width: 100%;
            height: 100vh;
        }

        .slick-slide div {
            width: inherit;
        }

        .slick-slide div li > img {
            display: flex;

            width: 100%;
            height: 100vh;

            object-fit: cover;
        }

        .dots-horizontal {
            position: absolute;

            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 104px;
            height: 8px;

            bottom: 90px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
        }

        .dots-horizontal li::after {
            background: none;
        }
        
        .dots-horizontal li button {
            width: 26px;
            height: 8px;
            background-color: #808080;
            font-size: 0;
        }

        .dots-horizontal li.slick-active button {
            background-color: #FFFFFF;
        }

        .info {
            position: absolute;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            bottom: 120px;
            left: 50%;

            width: 100%;

            transform: translateX(-50%);
            z-index: 12;

            &-img {
                width: 273.16px;
                height: 26.88px;
                margin-bottom: 13px;
            }

            span, a {
                padding: 0 11px;
                text-align: center;
                font-size: 18px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 2px;
                color: var(--white);
            }
        }
    }

    &-carousel__vertical {
        .dots-vertical {
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 50%;
            left: 20px;
            z-index: 10;
        }

        
        .dots-vertical li:nth-child(1n+2) {
            margin-top: 14px;
        }
        
        .dots-vertical li button {
            position: relative;
            font-size: 0;
        }

        .dots-vertical li button:before {
            content: url('../../icons/close-white.svg');
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            z-index: -1; 
        }

        .dots-vertical li.slick-active button:before {
            content: url('../../icons/close.svg');
        }
    }
}