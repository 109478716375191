.products {
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        padding: 21px 0;
    }

    &-header__filter {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            border: unset;
            background: unset;
        }
    }

    &-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        width: 100%;
    }

    &-wrapper__item {
        position: relative;
        display: flex;
        flex-direction: column;

        gap: 13px;

        min-height: 365px;
    }

    &-wrapper__img-wrap {
        position: relative;
        width: 100%;
        height: 200px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .favorite,
        .favorite-bg {
            position: absolute;

            top: 10px;
            left: 10px;

            width: 18px;
            height: 16px;
        }

        .favorite-bg {
            display: none;

            &.active {
                display: flex;
            }
        }
    }

    &-wrapper__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        a {
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            text-transform: uppercase;
            color: var(--black);
        } 

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            color: var(--primary-500);
        }

        span {
            font-size: 22px;
            font-weight: 700;
            line-height: 25px;
            color: var(--black);
        }
    }

    &-wrapper__btn {
        position: absolute;

        left: 0;
        bottom: 0;

        height: 42px;
    }
}