:root { --black: #000000; --white: #FFFFFF; --primary-500: #F0671F; --br--3: 3px; --br--5: 5px; --br--8: 8px; --br--10: 10px; --br--12: 12px; --br--15: 15px; --br--20: 20px; --br--25: 25px; --br--30: 30px; --br--66: 66px; }

.icon-arrow { width: 1.77em; height: 1em; fill: initial; }

.icon-back-arrow { width: 1.06em; height: 1em; fill: initial; }

.icon-checkmark { width: 1.33em; height: 1em; fill: initial; }

.icon-close-white { width: 1em; height: 1em; fill: initial; }

.icon-close { width: 1em; height: 1em; fill: initial; }

.icon-cover { width: 1.08em; height: 1em; fill: initial; }

.icon-edit { width: 1em; height: 1em; fill: initial; }

.icon-favorite-bg { width: 1.14em; height: 1em; fill: initial; }

.icon-favorite { width: 1.11em; height: 1em; fill: initial; }

.icon-filter { width: 1.04em; height: 1em; fill: initial; }

.icon-gallery { width: 1em; height: 1em; fill: initial; }

.icon-hearth { width: 1em; height: 1em; fill: initial; }

.icon-home { width: 0.77em; height: 1em; fill: initial; }

.icon-ico-search { width: 1em; height: 1em; fill: initial; }

.icon-instagram { width: 1em; height: 1em; fill: initial; }

.icon-logo-black { width: 9.25em; height: 1em; fill: initial; }

.icon-logo { width: 10em; height: 1em; fill: initial; }

.icon-payment { width: 1em; height: 1em; fill: initial; }

.icon-shoppingbag { width: 1.02em; height: 1em; fill: initial; }

.icon-telegram { width: 1em; height: 1em; fill: initial; }

.icon-truck { width: 1.36em; height: 1em; fill: initial; }

.icon-vk { width: 1em; height: 1em; fill: initial; }

.icon-x { width: 1em; height: 1em; fill: initial; }

.icon-youtube { width: 1em; height: 1em; fill: initial; }

.icon-arrow { width: 1.77em; height: 1em; fill: initial; }

.icon-back-arrow { width: 1.06em; height: 1em; fill: initial; }

.icon-checkmark { width: 1.33em; height: 1em; fill: initial; }

.icon-close-white { width: 1em; height: 1em; fill: initial; }

.icon-close { width: 1em; height: 1em; fill: initial; }

.icon-cover { width: 1.08em; height: 1em; fill: initial; }

.icon-edit { width: 1em; height: 1em; fill: initial; }

.icon-favorite-bg { width: 1.14em; height: 1em; fill: initial; }

.icon-favorite { width: 1.11em; height: 1em; fill: initial; }

.icon-filter { width: 1.04em; height: 1em; fill: initial; }

.icon-gallery { width: 1em; height: 1em; fill: initial; }

.icon-hearth { width: 1em; height: 1em; fill: initial; }

.icon-home { width: 0.77em; height: 1em; fill: initial; }

.icon-ico-search { width: 1em; height: 1em; fill: initial; }

.icon-instagram { width: 1em; height: 1em; fill: initial; }

.icon-logo-black { width: 9.25em; height: 1em; fill: initial; }

.icon-logo { width: 10em; height: 1em; fill: initial; }

.icon-payment { width: 1em; height: 1em; fill: initial; }

.icon-shoppingbag { width: 1.02em; height: 1em; fill: initial; }

.icon-telegram { width: 1em; height: 1em; fill: initial; }

.icon-truck { width: 1.36em; height: 1em; fill: initial; }

.icon-vk { width: 1em; height: 1em; fill: initial; }

.icon-x { width: 1em; height: 1em; fill: initial; }

.icon-youtube { width: 1em; height: 1em; fill: initial; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

@media (prefers-reduced-motion: reduce) { * { transition: none !important; } }

@font-face { font-family: ar; src: url("../fonts/Arial-Regular.ttf"), url("../fonts/Arial-Regular.woff2"); font-weight: 400; font-style: normal; font-display: swap; }

@font-face { font-family: ar; src: url("../fonts/Arial-Bold.ttf"), url("../fonts/Arial-Bold.woff2"); font-weight: 700; font-style: normal; font-display: swap; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

body { font-weight: 400; font-size: 16px; line-height: 1.4; font-family: "ar", sans-serif; letter-spacing: 2px; color: var(--black-100); background-color: var(--white); }

.container { max-width: 1443px; margin-left: auto; margin-right: auto; padding: unset; }

.section { padding-left: 20px; padding-right: 20px; }

.section--filled { background-color: var(--white); }

.section h1, .section h2, .section h3, .section h4, .section h5 { font-weight: 500; }

.section h1 { font-size: 22px; }

.section h2 { font-size: 20px; }

.section h3 { font-size: 14px; color: var(--gray-700); }

.section a { text-decoration: none; }

@media only screen and (min-width: 1024px) { .section { padding-left: 30px; padding-right: 30px; } }

@media only screen and (min-width: 1366px) { .section { padding-left: 72px; padding-right: 72px; } }

.btn { display: flex; align-items: center; justify-content: center; width: 100%; height: 60px; letter-spacing: 2px; text-transform: uppercase; border: none; }

.btn--primary { background-color: var(--primary-500); color: var(--white); font-size: 18px; font-weight: 400; line-height: 21px; }

.btn--black { background-color: var(--black); color: var(--white); font-size: 14px; font-weight: 400; line-height: 16px; }

.breadcrumbs-back { display: flex; align-items: center; gap: 23px; }

.breadcrumbs-back a { display: flex; width: 18.41px; height: 17.41px; }

.breadcrumbs-back span { font-size: 18px; font-weight: 400; line-height: 21px; }

.nav { position: fixed; width: 100%; bottom: 0; left: 0; z-index: 20; }

.nav.filled { background-color: #F5F4E4; }

.nav.filled .nav-list__item a { color: var(--black); }

.nav.filled .nav-list__item a .icon-home { fill: var(--black); }

.nav-list { display: flex; align-items: center; justify-content: space-between; padding: 17px 20px 17px 30px; width: 100%; }

@media only screen and (min-width: 365px) { .nav-list { padding: 17px 20px 17px 47px; } }

.nav-list__item a { display: flex; font-size: 12px; font-weight: 400; line-height: 14px; letter-spacing: 2px; text-decoration: none; color: var(--white); }

.nav-list__item a.active { font-weight: 700; }

.nav-list__item a .icon-home { width: 33.16px; height: 42.35px; fill: var(--white); stroke: unset; }

.content-section { display: none; grid-template-areas: "list-1 list-2" "list-1 list-2" "list-1 list-3" "list-1 list-3"; place-content: flex-start; gap: 26px; position: absolute; width: 100%; height: 100%; padding: 20px; background-color: var(--white); z-index: 15; }

.content-section.active { display: grid; }

.content-section__wrap { display: flex; flex-direction: column; gap: 40px; }

.content-section__wrap.list-1 { grid-area: list-1; }

.content-section__wrap.list-2 { grid-area: list-2; }

.content-section__wrap.list-3 { grid-area: list-3; }

.content-section__wrap.list-3 .content-section__item a { text-transform: uppercase; }

.content-section__title { font-size: 20px; font-weight: 700; line-height: 23px; color: var(--black); text-transform: uppercase; }

.content-section__list { display: flex; flex-direction: column; gap: 30px; }

.content-section__item a { font-size: 18px; font-weight: 400; line-height: 21px; color: var(--black); text-decoration: none; }

.content-section--about.active { display: flex; flex-direction: column; gap: 7px; }

.content-section--about .content-section__wrap { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 0; }

.content-section--about .content-section__logo-wrap { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 22px; }

.content-section--about .content-section__logo-wrap .icon-home { width: 37px; height: 48px; }

.content-section--about .content-section__logo-wrap .icon-logo { width: 146px; height: 14px; }

.content-section--about .content-section__dropdown-list { margin-top: 30px; display: flex; flex-direction: column; gap: 30px; width: 100%; }

.content-section--about .content-section__dropdown-item div { display: flex; align-items: center; justify-content: space-between; width: 100%; }

.content-section--about .content-section__dropdown-item div span { font-size: 20px; font-weight: 400; line-height: 23px; }

.content-section--about .content-section__dropdown-item div .arrow { width: 14px; height: 14px; border: solid var(--black); border-width: 0 2px 2px 0; display: inline-block; padding: 4.5px; transform: rotate(45deg); transition: .3s; }

.content-section--about .content-section__dropdown-item div .arrow.active { transform: rotate(224deg); }

.content-section--about .content-section__social-list { margin-top: 15px; display: flex; align-items: center; align-self: flex-start; }

@media only screen and (min-width: 365px) { .content-section--about .content-section__social-list { gap: 14px; } }

.content-section--about .content-section__newsletter { display: flex; flex-direction: column; gap: 20px; padding-bottom: 100px; max-width: 405px; }

.content-section--about .content-section__newsletter p { font-size: 16px; font-weight: 400; line-height: 18px; }

.content-section--about .content-section__newsletter input { width: 100%; height: 49px; display: flex; align-items: flex-start; padding-left: 22px; box-sizing: border-box; border: 1px solid var(--black); outline: none; background-color: var(--white); }

.content-section--about .content-section__newsletter input::placeholder, .content-section--about .content-section__newsletter input[type="email"] { font-size: 18px; font-weight: 400; line-height: 21px; letter-spacing: 2px; color: rgba(0, 0, 0, 0.5); }

.content-section--about .content-section__newsletter input[type="email"] { color: var(--black); }

.content-section--about .content-section__newsletter-list { display: flex; flex-direction: column; gap: 5px; }

.content-section--about .content-section__newsletter-list li { font-size: 14px; font-weight: 400; line-height: 16px; }

.content-section--about .content-section__newsletter-list li a { position: relative; font-weight: 700; color: var(--black); text-decoration: none; }

.content-section--about .content-section__newsletter-list li a::after { position: absolute; content: ''; width: 100%; height: 1px; bottom: -1px; left: 0; background-color: var(--black); }

.content-section--profile.active { display: flex; align-items: flex-start; }

.content-section--profile .content-section__profile-list { display: grid; grid-template-columns: repeat(3, 1fr); gap: 8px; }

.content-section--profile .content-section__profile-item { align-self: stretch; height: 100%; min-height: 110px; }

.content-section--profile .content-section__profile-item a { display: flex; flex-direction: column; align-items: center; justify-content: flex-start; padding: 11px; gap: 12px; height: 100%; box-sizing: border-box; border: 1px solid var(--black); text-decoration: none; }

.content-section--profile .content-section__profile-item a span { text-align: center; font-size: 12px; font-weight: 400; line-height: 14px; color: var(--black); }

.header { position: fixed; top: 20px; left: 0; width: 100%; z-index: 10; }

.header-wrap { display: flex; align-items: center; justify-content: flex-end; gap: 15px; padding: 0 0 0 5px; }

.header-wrap__field { display: none; width: 100%; height: 35px; }

.header-wrap__field input { padding-left: 15px; width: 100%; height: 100%; background-color: var(--white); }

.header-wrap__field input[type="text"] { font-size: 18px; font-weight: 400; line-height: 21px; letter-spacing: 1px; }

.header-wrap__field.active { display: flex; }

.header-wrap__search { display: flex; }

.hero-carousel, .hero-carousel__vertical { position: relative; width: 100%; }

.hero-carousel li, .hero-carousel__vertical li { position: relative; }

.hero-carousel li .shadow, .hero-carousel__vertical li .shadow { position: absolute; bottom: 0; right: 0; width: 100%; height: 57%; background: linear-gradient(0deg, black 1.327%, rgba(0, 0, 0, 0) 44.243%); z-index: 1; }

.hero-carousel .slick-list, .hero-carousel__vertical .slick-list { width: 100%; height: 100vh; }

.hero-carousel .slick-slide div, .hero-carousel__vertical .slick-slide div { width: inherit; }

.hero-carousel .slick-slide div li > img, .hero-carousel__vertical .slick-slide div li > img { display: flex; width: 100%; height: 100vh; object-fit: cover; }

.hero-carousel .dots-horizontal, .hero-carousel__vertical .dots-horizontal { position: absolute; display: flex; align-items: center; justify-content: space-between; width: 104px; height: 8px; bottom: 90px; left: 50%; transform: translateX(-50%); z-index: 10; }

.hero-carousel .dots-horizontal li::after, .hero-carousel__vertical .dots-horizontal li::after { background: none; }

.hero-carousel .dots-horizontal li button, .hero-carousel__vertical .dots-horizontal li button { width: 26px; height: 8px; background-color: #808080; font-size: 0; }

.hero-carousel .dots-horizontal li.slick-active button, .hero-carousel__vertical .dots-horizontal li.slick-active button { background-color: #FFFFFF; }

.hero-carousel .info, .hero-carousel__vertical .info { position: absolute; display: flex; flex-direction: column; align-items: center; justify-content: center; bottom: 120px; left: 50%; width: 100%; transform: translateX(-50%); z-index: 12; }

.hero-carousel .info-img, .hero-carousel__vertical .info-img { width: 273.16px; height: 26.88px; margin-bottom: 13px; }

.hero-carousel .info span, .hero-carousel .info a, .hero-carousel__vertical .info span, .hero-carousel__vertical .info a { padding: 0 11px; text-align: center; font-size: 18px; font-weight: 400; line-height: 21px; letter-spacing: 2px; color: var(--white); }

.hero-carousel__vertical .dots-vertical { position: absolute; display: flex; flex-direction: column; top: 50%; left: 20px; z-index: 10; }

.hero-carousel__vertical .dots-vertical li:nth-child(1n+2) { margin-top: 14px; }

.hero-carousel__vertical .dots-vertical li button { position: relative; font-size: 0; }

.hero-carousel__vertical .dots-vertical li button:before { content: url("../../icons/close-white.svg"); position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-size: cover; z-index: -1; }

.hero-carousel__vertical .dots-vertical li.slick-active button:before { content: url("../../icons/close.svg"); }

.products-header { display: flex; align-items: center; justify-content: space-between; width: 100%; padding: 21px 0; }

.products-header__filter { display: flex; align-items: center; justify-content: center; }

.products-header__filter button { border: unset; background: unset; }

.products-wrapper { display: grid; grid-template-columns: repeat(2, 1fr); gap: 20px; width: 100%; }

.products-wrapper__item { position: relative; display: flex; flex-direction: column; gap: 13px; min-height: 365px; }

.products-wrapper__img-wrap { position: relative; width: 100%; height: 200px; }

.products-wrapper__img-wrap img { width: 100%; height: 100%; object-fit: cover; }

.products-wrapper__img-wrap .favorite, .products-wrapper__img-wrap .favorite-bg { position: absolute; top: 10px; left: 10px; width: 18px; height: 16px; }

.products-wrapper__img-wrap .favorite-bg { display: none; }

.products-wrapper__img-wrap .favorite-bg.active { display: flex; }

.products-wrapper__info { display: flex; flex-direction: column; align-items: flex-start; gap: 10px; }

.products-wrapper__info a { font-size: 16px; font-weight: 400; line-height: 18px; text-transform: uppercase; color: var(--black); }

.products-wrapper__info p { font-size: 14px; font-weight: 400; line-height: 16px; color: var(--primary-500); }

.products-wrapper__info span { font-size: 22px; font-weight: 700; line-height: 25px; color: var(--black); }

.products-wrapper__btn { position: absolute; left: 0; bottom: 0; height: 42px; }

.filter { position: fixed; display: none; top: 0; left: 0; padding: 23px 20px; width: 100%; height: 100%; background-color: var(--white); z-index: 12; }

.filter.active { display: flex; flex-direction: column; overflow: scroll; padding-bottom: 100px; }

.filter-header__back { display: flex; align-items: center; gap: 20px; }

.filter-header__back a { display: flex; }

.filter-container { margin-top: 30px; display: flex; flex-direction: column; gap: 30px; }

.filter-container div h3 { font-size: 16px; font-weight: 400; line-height: 18px; text-transform: uppercase; margin-bottom: 20px; }

.filter-container__colors-list { display: flex; flex-wrap: wrap; gap: 15px; }

.filter-container__colors-item { position: relative; display: flex; width: 30px; height: 30px; border-radius: 50%; }

.filter-container__colors-item.active::after { position: absolute; content: ''; display: inline-block; height: 20px; width: 9px; top: 7px; left: 48%; border-bottom: 2px solid var(--black); border-right: 2px solid var(--black); transform: rotate(45deg) translateX(-48%); }

.filter-container__colors-item.white { border: 1px solid var(--black); background-color: var(--white); }

.filter-container__colors-item.black { background-color: var(--black); }

.filter-container__colors-item.black.active::after { border-bottom: 2px solid var(--white); border-right: 2px solid var(--white); }

.filter-container__colors-item.grey { background-color: #D9D9D9; }

.filter-container__colors-item.orange { background-color: #FDB600; }

.filter-container__colors-item.purple { background-color: #984995; }

.filter-container__colors-item.green { background-color: #63B22F; }

.filter-container__colors-item.blue { background-color: #2252B0; }

.filter-container__colors-item.red { background-color: #A82420; }

.filter-container__colors-item.pink { background-color: #EFA4A2; }

.filter-container__colors-item.brown { background-color: #975C17; }

.filter-container__colors-item.yellow { background-color: #FFE70D; }

.filter-container__colors-item.blue-light { background-color: #A3C2FF; }

.filter-container__sizes-list, .filter-container__gender-list { display: flex; flex-wrap: wrap; gap: 10px; }

.filter-container__sizes-item, .filter-container__gender-item { display: flex; align-items: center; justify-content: center; padding: 10px; min-width: 50px; border: 1px solid var(--black); font-size: 16px; font-weight: 400; line-height: 18px; text-transform: uppercase; }

.filter-container__sizes-item.active, .filter-container__gender-item.active { background-color: var(--black); color: var(--white); }

.filter-container__price-range { display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; margin-top: 20px; gap: 14px; width: fit-content; }

.filter-container__price-range input { position: relative; text-align: center; border: none; border-bottom: 1px solid #ccc; font-size: 16px; }

.filter-container__price-range input::placeholder { font-size: 16px; font-weight: 400; line-height: 18px; color: rgba(0, 0, 0, 0.5); }

.filter-container__price-range input:focus { outline: none; border-bottom: 1px solid #000; }

.filter-container__range-label { color: #aaa; font-size: 16px; }

.filter-container__checkbox label { display: flex; align-items: center; justify-content: flex-start; gap: 10px; cursor: pointer; margin-bottom: 10px; }

.filter-container__checkbox label:last-child { margin-bottom: 0; }

.filter-container__checkbox label span { font-size: 18px; font-weight: 400; line-height: 22px; }

.filter-container__checkbox input[type="checkbox"] { appearance: none; display: flex; align-content: center; justify-content: center; width: 24px; height: 24px; border: 1px solid var(--black); }

.filter-container__checkbox input[type="checkbox"]::before { content: ""; width: 24px; height: 22px; transform: scale(0); background: url("../../icons/checkmark.svg"); background-repeat: no-repeat; background-size: 16px; background-position: center; background-color: var(--black); border: none; }

.filter-container__checkbox input[type="checkbox"]:checked::before { transform: scale(1); border: none; }

.personal-data { padding: 20px 0; }
