// breakpoints
$phone-s: 365;
$phone-m: 414;
$tablet: 767;
$desktop-xs: 940;
$desktop: 1024;
$desktop-lg: 1366;

// fonts
$font-main: "ar", sans-serif;

$max-width: 1443px;

:root {
  --black: #000000;
  --white: #FFFFFF;

  --primary-500: #F0671F;

  // border radius
  --br--3: 3px;
  --br--5: 5px;
  --br--8: 8px;
  --br--10: 10px;
  --br--12: 12px;
  --br--15: 15px;
  --br--20: 20px;
  --br--25: 25px;
  --br--30: 30px;
  --br--66: 66px;
}