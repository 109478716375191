.header {
    position: fixed;

    top: 20px;
    left: 0;

    width: 100%;

    z-index: 10;

    &-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        gap: 15px;

        padding: 0 0 0 5px;
    }

    &-wrap__field {
        display: none;
        width: 100%;
        height: 35px;

        input {
            padding-left: 15px;
            width: 100%;
            height: 100%;
            background-color: var(--white);
        }

        input[type="text"] {
            font-size: 18px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 1px;
        }

        &.active {
            display: flex;
        }
    }

    &-wrap__search {
        display: flex;
    }
}