.breadcrumbs-back {
    display: flex;
    align-items: center;
    gap: 23px;

    a {
        display: flex;
        width: 18.41px;
        height: 17.41px;
    }

    span {
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
    }
}