.nav {
    position: fixed;

    width: 100%;

    bottom: 0;
    left: 0;
    z-index: 20;

    &.filled {
        background-color: #F5F4E4;
    }

    &.filled &-list__item a {
        color: var(--black);

        .icon-home {
            fill: var(--black);
        }
    }

    &-list {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 17px 20px 17px 30px;
        
        width: 100%;

        @include rmin($phone-s) {
            padding: 17px 20px 17px 47px;
        }
    }

    &-list__item a {
        display: flex;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 2px;
        text-decoration: none;
        color: var(--white);

        &.active {
            font-weight: 700;
        }

        .icon-home {
            width: 33.16px;
            height: 42.35px;
            fill: var(--white);
            stroke: unset;
        }
    }
}

.content-section {
    display: none;
    grid-template-areas: 
    "list-1 list-2"
    "list-1 list-2"
    "list-1 list-3"
    "list-1 list-3"
    ;
    place-content: flex-start;
    gap: 26px; 

    position: absolute;

    width: 100%;
    height: 100%;

    padding: 20px;

    background-color: var(--white);
    z-index: 15;

    &.active {
        display: grid;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        gap: 40px;

        &.list-1 {
            grid-area: list-1;
        }

        &.list-2 {
            grid-area: list-2;
        }

        &.list-3 {
            grid-area: list-3;
        }

        &.list-3 &-title {

        }
    }

    &__wrap.list-3 &__item a {
        text-transform: uppercase;
    }

    &__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        color: var(--black);
        text-transform: uppercase;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    &__item a {
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        color: var(--black);
        text-decoration: none;
    }
}

.content-section--about {
    &.active {
        display: flex;
        flex-direction: column;
        gap: 7px;
    }

    .content-section__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0;
    }

    .content-section__logo-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 22px;

        .icon-home {
            width: 37px;
            height: 48px;
        }

        .icon-logo {
            width: 146px;
            height: 14px;
        }
    }

    .content-section__dropdown-list {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
    }

    .content-section__dropdown-item div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        span {
            font-size: 20px;
            font-weight: 400;
            line-height: 23px;
        }

        .arrow {
            width: 14px;
            height: 14px;
            border: solid var(--black);
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 4.5px;
            transform: rotate(45deg);
            transition: .3s;
        }

        .arrow.active {
            transform: rotate(224deg);
        }
    }

    .content-section__social-list {
        margin-top: 15px;
        display: flex;
        align-items: center;
        align-self: flex-start;
        
        @include rmin($phone-s) {
            gap: 14px;
        }
    }

    .content-section__newsletter {
        display: flex;
        flex-direction: column;

        gap: 20px;

        padding-bottom: 100px;

        max-width: 405px;

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
        }

        input {
            width: 100%;
            height: 49px;
            display: flex;
            align-items: flex-start;
            padding-left: 22px;

            box-sizing: border-box;
            border: 1px solid var(--black);
            outline: none;
            background-color: var(--white);
        }

        input::placeholder,
        input[type="email"] {
            font-size: 18px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 2px;
            color: rgba(0, 0, 0, 0.5);
        }

        input[type="email"] {
            color: var(--black);
        }
    }

    .content-section__newsletter-list {
        display: flex;
        flex-direction: column;
        gap: 5px;

        li {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
        }

        li a {
            position: relative;
            font-weight: 700;
            color: var(--black);
            text-decoration: none;
        }

        li a::after {
            position: absolute;

            content: '';

            width: 100%;
            height: 1px;

            bottom: -1px;
            left: 0;

            background-color: var(--black);
        }
    }
}

.content-section--profile {
    &.active {
        display: flex;
        align-items: flex-start;
    }

    .content-section__profile-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
    }

    .content-section__profile-item {
        align-self: stretch;
        height: 100%;
        min-height: 110px;
    }

    .content-section__profile-item a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        padding: 11px;

        gap: 12px;
        height: 100%;

        box-sizing: border-box;
        border: 1px solid var(--black);
        text-decoration: none;

        span {
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            color: var(--black);
        }
    }
}